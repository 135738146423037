<template>
    <v-container id="conf_settings_firma" class="w-container send_doc_user_2sign" style="padding-top:59px">


        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                    background-color: white;
                    color: black;
                    font-weight: bold;
                    padding: 4px;
                    font-size: 10px;
                    min-width:100vw;
                    border-color:#da6a2d">{{ titolo }}


                </div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-row id="rowMobile">

                    <v-col cols="12" md="6" xs="6">

                        <v-select v-model="valuePrefixMobile" :items="prefissi" label="Prefix" item-text="prefix"
                            item-value="id"></v-select>

                    </v-col>

                    <v-col cols="12" md="6" xs="6">

                        <v-text-field v-model="valueMobile" label="Mobile"></v-text-field>

                    </v-col>

                </v-row>

            </v-row>


        </v-form>


        <div class="contpopupotplogin" style="display:none">

            <template>
                <modal name="popupOtpLogin" :clickToClose="false" :width="262" :height="221">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;font-size:12px;text-align: center;">

                            OTP code sent to you via SMS

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="text" v-model="valueOtpLogin" label="" id="fldValueOtpSignAlertMsg"
                                onkeydown="if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace') event.preventDefault();">
                            </v-text-field>



                        </v-col>

                    </v-row>

                    <v-row v-if="showResend">

                        <v-col cols="12" md="12" style="display: flex;
justify-content: center;
padding-top: 0px!important;
margin-top: 0px!important;
font-size:12px">

                            <div style="font-weight: bold;
cursor: pointer;" class="clReinviaOtp2">Resend</div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-confirm-otp-login" contain
                                    src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmOtpLogin" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>

import apisettings from "../utils/settings/apisettings";
import router from ".././router";
import apiuploadfile from "../utils/uploadfile/apiuploadfile";


import {
    bus
} from "../main";
import apimessaggi from "../utils/messaggi/apimessaggi";


export default ({
    mounted() {

        this.$root.$children[0].buttonsInfoUser = [];
        this.$root.$children[0].totalMsg = "";

        window.nameDoc = "";
        window.surnameDoc = "";
        window.mailaddressDoc = "";
        window.titoloDoc = "";
        window.fileAttachment = null;



        /*eslint-disable no-undef*/


        var pointerVue = this;

        this.setupButtons();



        this.titolo = "Verify Mobile";



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {


                case "btn_next":

                    this.validate();

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '.clReinviaOtp2', function (event) {
                event.preventDefault();



                pointerVue.showResend = false;

                pointerVue.$modal.hide('popupOtpLogin');


                pointerVue.getOtpValue();


            });



            $(".tipo").on("ionChange", function (opt) {
                console.log(opt);

                console.log("VAL: ", $(this).val());


                if ($(this).val() == "Testo") {

                    pointerVue.viewImage = false;
                    pointerVue.viewSign = false;
                    pointerVue.viewText = true;

                    $(".divFirma").hide();

                }

                if ($(this).val() == "Firma") {

                    pointerVue.viewSign = true;
                    pointerVue.viewImage = false;
                    pointerVue.viewText = false;

                    $(".divFirma").show();

                    pointerVue.$refs.signaturePad.openSignaturePad();
                    pointerVue.$refs.signaturePad.clearSignature();

                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    }, 300);

                }

                if ($(this).val() == "Immagine") {

                    pointerVue.viewImage = true;
                    pointerVue.viewSign = false;
                    pointerVue.viewText = false;

                    $(".divFirma").hide();

                    setTimeout(() => {

                        pointerVue.formatDropify();

                    }, 100);

                }


            });


        });

        $(".bottomBtnMenu").hide();


        // centro in verticale campo telefono
        var altezzaFinestra = window.innerHeight;

        var posizioneCentraleY = altezzaFinestra / 2;

        posizioneCentraleY = posizioneCentraleY - 200;

        var strPosizione = posizioneCentraleY.toString() + "px";

        console.log("POSZIONE: ", strPosizione);

        $("#rowMobile").css("margin-top", strPosizione);



        setTimeout(() => {

            this.$swal({
                icon: "warning",
                text: "Specify your mobile",
                showConfirmButton: true,
                confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertmsg.png' />",
            });

        }, 500);

    },


    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },


    data: () => ({

        valuePrefixMobile: "",
        valueMobile: "",

        showResend: false,

        valueOtpLogin: "",

        prefissi: [
            {
                id: "+39",
                prefix: "+39"
            }
        ],

        valueEMail: "",
        // valuePrefixMobile: "",
        // valueMobile: "",

        definput: {
            name: "",
            surname: "",
            mailaddress: "",
            titolo: ""
        },


        viewAttach: "S",
        signAttach: "S",


        attachPresent: false,

        // parametri funzione upload
        testoPulsante: "Upload Attachment",
        titoloPopup2: "Attach Document",
        fileAccettati: ".pdf,.png,.jpg,.jpeg",
        nomeFilePresente: "",
        dimFilePresente: "",
        dimKbFilePresente: 0,
        dimMinKbFile: 100000,
        dimMaxKbFile: 5000000,
        folderServerToSave: "/documents/alertmsg/",
        folderDoxToSave: "/Products/Alertmsg/",
        removeUploadFile: 0,
        attachRemoveVisura: false,



        oggetto: "",

        hasFile: false,

        valueReadonly: false,

        currentFile: null,

        tipi: [{ id: "Firma", name: "Firma" }, { id: "Immagine", name: "Immagine" }],

        isDefault: false,

        sheetSignature: false,

        totSignatures: 0,
        totImages: 0,

        viewText: false,
        viewImage: false,
        viewSign: false,

        coordXFirma: null,
        coordYFirma: null,

        firma: {},

        fieldDis: false,

        showRow: true,
        showRow2: true,

        //  currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Firma",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,



    }),

    watch: {
        viewAttach(newValue) {
            // Se viewAttach è "N", imposta signAttach a "N"
            if (newValue === 'N') {
                this.signAttach = 'N';
            }
        }
    },

    methods: {

        updateRowVer: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.updateRowVerPhone(
                v_token,
                that.valuePrefixMobile,
                that.valueMobile
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateRowVerPhone", res);

                that.$swal({
                    icon: "success",
                    text: "Mobile successfully verified",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    router.push({
                        path: "/messages"
                    });


                }, 2000);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        btnConfirmOtpLogin: function () {

            var that = this;


            if (that.valueOtpLogin != "") {

                console.log("OTP INP: ", that.valueOtpLogin);
                console.log("ENC OTP INP: ", btoa(that.valueOtpLogin));

                if (window.myVal == btoa(that.valueOtpLogin)) {

                    that.$modal.hide('popupOtpLogin');

                    that.updateRowVer();


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: "Incorrect OTP value",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.valueOtpLogin = "";
                        $("#fldValueOtpSignAlertMsg").focus();

                    }, 2500);


                }



            }
            else {


                $("#fldValueOtpSignAlertMsg").focus();

                that.$swal({
                    icon: "error",
                    text: "Specify an OTP value",
                    showConfirmButton: false,
                    timer: 2000
                });

            }


        },

        getOtpValue: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getValueForOtp(
                "mauro_cipolla",
                "emt",
                that.valueMobile,
                that.valuePrefixMobile
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getValueForOtp", res);

                window.myVal = res.data.ValReturn;


                setTimeout(() => {

                    that.showResend = true;

                    that.valueOtpLogin = "";

                    $(".contpopupotplogin").show();

                    that.$modal.show('popupOtpLogin');



                    setTimeout(() => {

                        $("#fldValueOtpSignAlertMsg").focus();

                    }, 300);

                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },



        validateEMail: function () {

            var result;

            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            console.log("MAIL: ", $("#mailaddress").val());

            if ($("#mailaddress").val() != undefined) {

                if ($("#mailaddress").val() != "") {

                    if ($("#mailaddress").val().match(validRegex)) {
                        result = true;
                    }
                    else {
                        result = false;

                    }

                }
                else {

                    result = true;

                }



            }
            else {
                result = true;

            }


            return result;

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        btnConfirmAttachProperty: function () {

            window.viewAttach = this.viewAttach;
            window.signAttach = this.signAttach;
            window.id_rec = this.$route.params.id;

            window.oggetto = this.oggetto;

            window.testo = this.testo;

            //this.uploadFile();

            if (window.signAttach == "N") {

                router.push({
                    path: "/filterSelectUsers"
                });


            }

            if (window.signAttach == "S") {

                this.uploadFileForSetSign();

            }

        },

        uploadFileForSetSign: async function () {

            var that = this;



            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response3 = await apimessaggi.uploadFileForSetSign2Sign(
                v_token,
                window.fileAttachment
            ).then(async (res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadFileForSetSign2Sign", res2);

                if (res2.data.Result == "OK") {

                    window.pathFileTemp = res2.data.UrlPath;

                    setTimeout(() => {

                        router.push({
                            path: "/editDocumentSetSign"
                        });

                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res2.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }




            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );



        },

        openPopupAttachProperty: async function () {

            var that = this;

            window.fileAttachment = $("#input-file-attach")[0].files[0];

            that.$modal.hide('popupUploadFile');

            setTimeout(() => {

                $(".contpopupuattachproperty").show();

                that.$modal.show('popupAttachProperty');



            }, 200);



        },


        uploadFile: async function () {


            var that = this;

            console.log("FATT FILE: ", that.fattura);

            var id_rec = that.$route.params.id;


            var v_file = window.fileAttachment;

            that.$modal.hide('popupAttachProperty');


            that.attachPresent = true;


            console.log("ID_REC: ", id_rec);
            console.log("FILE: ", v_file);
            console.log("FOLDER SERVER: ", that.folderServerToSave);
            console.log("FOLDER DOX: ", that.folderDoxToSave);


            var title = $("#oggetto").val();
            var v_id_users_doc_send = window.$cookies.get("id_users_doc_send");

            console.log("ID USER: ", v_id_users_doc_send);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;




            var response3 = await apiuploadfile.setFileAlertMsgUser(
                id_rec,
                v_file,
                that.folderServerToSave,
                that.folderDoxToSave,
                "allegato_utenti_alertmsg",
                v_token,
                that.removeUploadFile,
                v_id_users_doc_send,
                title
            ).then(async (res2) => {

                console.log(response3);

                console.log("res from setFileAlertMsgUser", res2);


                that.saveData();



            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },





        uploadFileOld: async function () {


            var that = this;

            console.log("FATT FILE: ", that.fattura);

            var id_rec = that.$route.params.id;


            var v_file = $("#input-file-attach")[0].files[0];

            that.$modal.hide('popupUploadFile');


            that.attachPresent = true;


            console.log("ID_REC: ", id_rec);
            console.log("FILE: ", v_file);
            console.log("FOLDER SERVER: ", that.folderServerToSave);
            console.log("FOLDER DOX: ", that.folderDoxToSave);


            var title = $("#oggetto").val();
            var v_id_users_doc_send = window.$cookies.get("id_users_doc_send");

            console.log("ID USER: ", v_id_users_doc_send);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            var response3 = await apiuploadfile.setFileAlertMsgUser(
                id_rec,
                v_file,
                that.folderServerToSave,
                that.folderDoxToSave,
                "allegato_utenti_alertmsg",
                v_token,
                that.removeUploadFile,
                v_id_users_doc_send,
                title
            ).then(async (res2) => {

                console.log(response3);

                console.log("res from setFileAlertMsgUser", res2);


                that.saveData();



            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },

        btnCancelUpload: function () {

            this.$modal.hide('popupUploadFile');

        },


        btnConfirmUpload: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];


            if (v_file.files.length == 0) {

                errore = "Attachment required";

            }


            if (v_file.files.length > 0) {



                // controllo estensione

                v_file = v_file.files[0];

                console.log("FILE 1: ", v_file);

                var v_elements = v_file.name.split('.');

                var v_ext_file = v_elements[v_elements.length - 1];

                console.log("FILE ELEMENTS: ", v_elements);
                console.log("FILE EXT: ", v_ext_file);


                var v_ele_file_accept = this.fileAccettati.split(',');

                console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                var v_find_ext = 0;

                for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                    var v_ext_file_tmp = "." + v_ext_file;

                    console.log(v_ext_file_tmp);

                    if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                        v_find_ext = 1;
                    }

                }

                if (v_find_ext == 0) {
                    errore = "The accepted formats are " + this.fileAccettati;
                }



                if (errore == "") {

                    // controllo dimensione file

                    if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                        var strMin = (this.dimMinKbFile / 1000) + " Kb";

                        var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                        errore = "The file size must be between " + strMin + " and " + strMax;

                    }


                }






            }




            if (errore == "") {

                console.log("ok");

                // this.uploadFile();

                this.openPopupAttachProperty();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3500
                });


            }


        },



        openPopupUpload: async function () {

            var that = this;

            var errore = "";

            var title = $("#oggetto").val();
            var body = $("#body").val();


            if (title == "") {
                errore = "Title required";
            }

            if (errore == "") {

                if (body == "") {
                    errore = "Message required";
                }


            }


            if (errore == "") {

                var v_token = window.$cookies.get("token");

                that.nomeFilePresente = "";
                that.dimFilePresente = "";
                that.dimKbFilePresente = 0;


                var id_rec = that.$route.params.id;

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;



                var response3 = await apiuploadfile.getFile(
                    id_rec,
                    "allegato_utenti_alertmsg",
                    that.folderServerToSave,
                    v_token
                ).then((res2) => {

                    console.log(response3);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from getFile", res2);


                    that.dimMinKbFile = res2.data.MinFileSize;

                    that.dimMaxKbFile = res2.data.MaxFileSize;


                    $(".contpopupuploadfile").show();

                    this.$modal.show('popupUploadFile');


                    setTimeout(() => {


                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);

                            that.removeUploadFile = 1;

                            that.nomeFilePresente = "";
                            that.dimFilePresente = "";
                            that.dimKbFilePresente = 0;

                        });



                        $('#input-file-attach').on('change', function () {

                            that.removeUploadFile = 0;

                            var v_file = $("#input-file-attach")[0].files[0];

                            console.log("FILE: ", v_file);

                            that.nomeFilePresente = v_file.name;

                            var v_size = v_file.size;

                            that.dimKbFilePresente = v_size;

                            var str_size = "0 Kb";


                            if (v_size > 0) {

                                var v_division = v_size / 1000;

                                str_size = v_division.toFixed(1) + " Kb";


                            }



                            that.dimFilePresente = str_size;



                        });



                        console.log("FILE PRESENT: ", res2.data.NomeFile);

                        if (res2.data.NomeFile != null) {

                            if (res2.data.NomeFile != "") {

                                var elements = res2.data.NomeFile.split(".");

                                console.log("ELEMENTS: ", elements);

                                var v_ext = elements[elements.length - 1];

                                console.log("EXT: ", v_ext);

                                if (v_ext == "pdf") {

                                    $(".rowPrevFile").find('.dropify-render').append("<i class='dropify-font-file'></i>");
                                    $(".rowPrevFile").find('.dropify-render').append("<span class='dropify-extension'>" + v_ext + "</span>");
                                    $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                    $(".rowPrevFile").find(".dropify-preview").show("slow");

                                }
                                else {


                                    var v_url = this.getUrlDomain("public/" + this.folderServerToSave) + res2.data.NomeFile;



                                    $(".rowPrevFile").find('.dropify-render img').attr("src", v_url);
                                    $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                    $(".rowPrevFile").find(".dropify-preview").show("slow");


                                }




                                that.nomeFilePresente = res2.data.NomeFile;

                                that.dimKbFilePresente = res2.data.FileSize;


                                var str_size = "0 Kb";

                                var v_size = res2.data.FileSize;

                                if (v_size > 0) {

                                    var v_division = v_size / 1000;

                                    str_size = v_division.toFixed(1) + " Kb";


                                }



                                that.dimFilePresente = str_size;

                            }


                        }








                    }, 300);



                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }






        },



        getUrlFolder: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },


        formatDropify: function () {

            var that = this;

            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

                that.hasFile = false;

            });

        },


        undo() {
            this.$refs.signaturePad.undoSignature();
        },


        saveData: function () {

            var that = this;

            var title = $("#oggetto").val();
            var body = $("#body").val();

            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");
            var v_id_users_doc_send = window.$cookies.get("id_users_doc_send");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            try {
                var response = this.$api.insertMultiMessageDemoNew(
                    token,
                    username,
                    title,
                    body,
                    0,
                    undefined,
                    v_id_users_doc_send,
                    1,
                    that.viewAttach,
                    that.signAttach
                );

                console.log(response);

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                this.$swal({
                    icon: "success",
                    text: "Il tuo messaggio è stato inviato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });



                setTimeout(() => {

                    router.push({
                        path: "/messages"
                    });


                }, 2000);






            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }






        },





        saveDataOld: function () {

            var that = this;

            var title = $("#oggetto").val();
            var body = $("#body").val();

            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");
            var v_id_users_doc_send = window.$cookies.get("id_users_doc_send");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;



            try {
                var response = this.$api.insertMultiMessageDemo(
                    token,
                    username,
                    title,
                    body,
                    0,
                    undefined,
                    v_id_users_doc_send,
                    1
                );

                console.log(response);

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                this.$swal({
                    icon: "success",
                    text: "Il tuo messaggio è stato inviato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });



                setTimeout(() => {

                    router.push({
                        path: "/messages"
                    });


                }, 2000);






            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }






        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Next",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_next",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/ok-alertmsg.png",
                    title: "Confirm",
                    width: 30

                },




                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var errore = "";

            var that = this;


            if (that.valuePrefixMobile == "") {
                errore = "Prefix required";
            }

            if (errore == "") {
                if (that.valueMobile == "") {
                    errore = "Mobile required";
                }
            }

            if (errore == "") {

                console.log("ok");

                window.prefixMobileDestination = that.valuePrefixMobile.toString();
                window.mobileDestination = that.valueMobile.toString();

                that.getOtpValue();



            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },


        syncDefaultCheckbox: async function () {

            var that = this;

            //     var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            // var response = await apifirme.getDefaultCheckbox(
            //     v_token,
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from getDefaultCheckbox", res);

            //     that.totSignatures = res.data.TotSignatures;
            //     that.totImages = res.data.TotImages;


            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("ERrori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );



        },


        syncSign: async function () {

            //  var that = this;

            //var signaturePad = this.$refs.signaturePad;

            //  var v_token = window.$cookies.get("token");



            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apifirme.getSign(
            //     v_token,
            //     that.$route.params.id
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from getSign", res);

            //     that.firma = res.data.signature;



            //     console.log("DEFAULT: ", res.data.signature.is_default);

            //     if (res.data.signature.is_default == 1) {

            //         console.log("OK");

            //         that.valueReadonly = true;

            //         setTimeout(() => {

            //             $(".checkDefault").prop("checked", true);

            //         }, 200);



            //     }


            //     if (res.data.signature.content_signature != null) {

            //         //console.log("CONTENT SIGN: ", res.data.signature.content_signature);

            //         var content = res.data.signature.content_signature;

            //         console.log("CONTENT SIGN: ", content);

            //         setTimeout(() => {
            //             $("canvas").hide();
            //             $("canvas").show();


            //             setTimeout(() => {

            //                 var canv = document.getElementsByTagName("canvas")[0];
            //                 var ctx = canv.getContext("2d");

            //                 var myImage = new Image();
            //                 //myImage.width = 200;

            //                 //console.log("myImage: ", myImage);

            //                 myImage.src = content;
            //                 //ctx.drawImage(myImage, 0, 0, 960, 200);

            //                 ctx.drawImage(myImage, 0, 0, 480, 100);

            //                 // window.dispatchEvent(new Event('resize'));


            //                 //that.$refs.signaturePad.fromData(content);

            //             }, 500);

            //         }, 100);


            //     }



            //     if (res.data.signature.file_immagine != null) {

            //         var tipo_immagine = res.data.signature.tipo_immagine;

            //         console.log("TIPO IMMAGINE: ", tipo_immagine);


            //         that.hasFile = true;

            //         var txt_image = "";

            //         var nome_file = res.data.signature.file_immagine;

            //         var elements = nome_file.split(".");

            //         var extension = elements[elements.length - 1];

            //         console.log("EXT: ", extension);




            //         if (tipo_immagine == "documento") {

            //             txt_image = extension;
            //         }

            //         if (tipo_immagine == "immagine") {

            //             txt_image = this.getUrlFolder("public/docsign/" + res.data.signature.file_immagine);
            //         }


            //         console.log("ALLEGATO: ", txt_image);

            //         setTimeout(() => {

            //             if (tipo_immagine == "immagine") {

            //                 $(".dropify-render img").attr("src", txt_image);
            //                 $(".dropify-wrapper").addClass("has-preview");
            //                 $(".dropify-preview").show("slow");

            //             }

            //             if (tipo_immagine == "documento") {

            //                 $('.dropify-render').append("<i class='dropify-font-file'></i>");
            //                 $('.dropify-render').append("<span class='dropify-extension'>" + txt_image + "</span>");

            //                 // dropify-font-file
            //                 $(".dropify-wrapper").addClass("has-preview");
            //                 $(".dropify-preview").show("slow");

            //             }

            //             // if (isImage) {
            //             //     $(".dropify-render img").attr("src", txt_image);
            //             //     $(".dropify-wrapper").addClass("has-preview");
            //             //     $(".dropify-preview").show("slow");

            //             // }
            //             // else {

            //             //     $('.dropify-render').append("<i class='dropify-font-file'></i>");
            //             //     $('.dropify-render').append("<span class='dropify-extension'>" + txt_image + "</span>");

            //             //     // dropify-font-file
            //             //     $(".dropify-wrapper").addClass("has-preview");
            //             //     $(".dropify-preview").show("slow");

            //             // }



            //         }, 200);


            //     }







            // }).catch(err => {
            //     //that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     console.log(response);

            //     //var msg = err.response.data.Error;

            //     // that.$swal({
            //     //     icon: "error",
            //     //     text: msg,
            //     //     showConfirmButton: false,
            //     //     timer: 8000
            //     // });
            //     // console.log("ERrori", "Non è stato possibile salvare i dati");
            //     // console.log("response", response);

            // }

            // );



        },


        syncConfInvioMail: async function () {

            // var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisettings.getSettings(
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSettings", res);

                try {

                    that.impostazioni = res.data.settings;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }



    }

})
</script>

<style>
.send_doc_user_2sign .rowPrevFile .dropify-wrapper {
    height: 187px !important;
    width: 363px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}

.buttonFileEditPagAlert {
    border: 1px solid black;
    padding: 10px;
    width: 183px;
}

.containButtonFileEditPagAlert {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
}



#conf_settings_firma .v-label {
    font-size: 1.2em !important;
}

#conf_settings_firma .v-input {
    font-size: 1em !important;
    padding: 10px !important;
}



.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}


.contSignature {
    margin: 0 auto;
}

.contSignature canvas {

    max-width: 98vw;
    border: 1px solid hsla(0, 0%, 50.2%, 0.33);
    padding-bottom: 10px;
    padding-top: 10px;
}

.buttonsPdf {
    margin-bottom: 150px;
    padding-top: 10px;
}

.buttonsPdf button {
    margin-right: 6px;
    margin-left: 6px;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.dropify-clear img {
    width: 20px !important;
}

.files label {
    margin-left: 0px !important;
}
</style>
