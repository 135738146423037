<template>
    <v-container fluid class="text-center d-flex align-center justify-center cont_messages"
        style="max-width: 100%; min-width: 300px;padding-bottom:100px;">



        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetDipendenti">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Dipendenti

                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchDipendenti"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                                    @click="manageClickDipendenti(objDip, 0)">
                                    <v-icon aria-hidden="false">mdi-account</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.fld_name }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetEmpty">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">

                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>

                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetGruppi">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Gruppi
                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchGruppi"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredGruppi" :key="key"
                                    @click="manageClickDipendenti(objDip, 1)">
                                    <v-icon aria-hidden="false">mdi-account-multiple</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.nome }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <v-dialog id='dialogMsgSend' content-class="dialogMsg" v-model="dialogMsgSend" width="100vw"
            style="min-width:100vw" max-width="500px">
            <v-card height="100vh" width="100vw">
                <v-card-title>

                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>

                            <v-col cols="12" sm="12" md="12">
                                <span class="v-input input">{{ "Messaggio a" }}<span class="corrente">{{
                                    nomeCompletoCorrente
                                        }}</span> </span>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field id='titolo' models="titolo" label="Oggetto"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea id='body' outlined name="input-7-4" label="Testo" models="body">
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <template>

                                    <span class="files">
                                        <label style="margin-left:11px;font-size:11px">Allegato</label>
                                        <input type="file" id="input-file-logo" name="logo"
                                            accept=".png,.jpg,.jpeg,.pdf" class="dropify" data-max-width="6610"
                                            data-max-height="6200" />
                                    </span>

                                </template>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog id='dialogMsgSendDemo' content-class="dialogMsg" v-model="dialogMsgSendDemo" width="100vw"
            style="min-width:100vw;" max-width="500px">
            <v-card height="100vh" width="100vw" class="cl_vcard">
                <v-card-title>

                </v-card-title>
                <v-card-text>
                    <v-container>

                        <v-row>

                            <v-col class='mainCont'
                                style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                                <div class="mainTitleInsMsg" style="border-color:#da6a2d!important">Inserimento
                                    Messaggio
                                </div>

                            </v-col>

                        </v-row>



                        <v-row class="fieldsMsgSendDemo">

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field id='name' ref="inputname" v-model="definput.name" label="Nome">
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field id='surname' v-model="definput.surname" label="Cognome"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field id='mailaddress' v-model="definput.mailaddress" label="Mail">
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field id='titolo' v-model="definput.titolo" label="Oggetto"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-textarea id='body' v-model="definput.testo" outlined name="input-7-4" label="Testo"
                                    models="body">
                                </v-textarea>
                            </v-col>




                            <v-col cols="12" sm="12" md="12">
                                <template>

                                    <span class="files">
                                        <label style="margin-left:11px;font-size:11px">Allegato</label>
                                        <input type="file" id="input-file-logo" name="logo"
                                            accept=".png,.jpg,.jpeg,.pdf" class="dropify" data-max-width="6610"
                                            data-max-height="6200" />
                                    </span>

                                </template>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                </v-card-actions>
            </v-card>
        </v-dialog>




        <template>

            <div>

                <v-dialog id='dialogMsg' content-class="dialogMsg" v-model="dialogMsg" width="100vw"
                    style="min-width:100vw" max-width="500px">

                    <v-card v-if="defaultItem.read == 1 || defaultItem.accepted == true" height="100vh" width="100vw">
                        <v-card-title>

                        </v-card-title>
                        <v-card-text>
                            <v-container>

                                <v-row>

                                    <v-col class='mainCont'
                                        style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                                        <div class="mainTitleInsMsg">Message</div>

                                    </v-col>

                                </v-row>



                                <v-row class="fieldsDialogMsg">



                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field readonly v-model="defaultItem.title" label="Subject">
                                        </v-text-field>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea readonly outlined name="input-7-4" label="Text"
                                            v-model="defaultItem.body"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                    </v-card>

                </v-dialog>

                <template>
                    <div class="cont-table-msg">


                        <div class="spin">
                            <ion-spinner name="crescent"></ion-spinner>
                        </div>

                        <div class="row cont_messaggi_base_users">
                            <div class=" " style="margin:0 auto;">
                                <div class="card">

                                    <div class="card-body" style="padding:0px">

                                        <div class="table-responsive" style="">

                                            <table id="myTable" style="margin-top:59px">
                                                <thead>
                                                    <tr>

                                                        <th class='all'>
                                                            <div class="cl_checkall">
                                                                <ion-checkbox
                                                                    style="width:10px!important;height:10px!important"
                                                                    name='emailgestoreall' class='checkall'
                                                                    slot='start'>
                                                                </ion-checkbox>
                                                            </div>
                                                        </th>

                                                        <th class='all'>
                                                            <div class="col_n">N.</div>
                                                        </th>

                                                        <th class='all' style="text-align: center;">Read</th>


                                                        <th class='desktop'
                                                            style='padding-top:5px!important;padding-bottom:5px!important;text-align: center;'>
                                                            Verify Mail</th>

                                                        <th class='desktop'
                                                            style='padding-top:5px!important;padding-bottom:5px!important;text-align: center;'>
                                                            Verify Phone</th>

                                                        <th class='desktop'
                                                            style='padding-top:5px!important;padding-bottom:5px!important;text-align: center;'>
                                                            Signed</th>

                                                        <th class='all'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Date</th>

                                                        <th class='all'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Title</th>

                                                        <th class='desktop'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Sender</th>

                                                        <th class='desktop'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Receiving</th>

                                                        <th class='all'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Surname</th>

                                                        <th class='desktop'
                                                            style='padding-top:5px!important;padding-bottom:5px!important'>
                                                            Company</th>

                                                        <th class='desktop'
                                                            style='padding-top:5px!important;padding-bottom:5px!important;text-align: center;'>
                                                            Att.</th>




                                                    </tr>
                                                </thead>

                                                <tfoot>

                                                    <tr>
                                                        <th colspan="13">
                                                        </th>
                                                    </tr>
                                                </tfoot>

                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="contpopupverify" style="display:none">

                            <template>
                                <modal name="popupVerify" :clickToClose="false" :width="262" :height="236">


                                    <v-row>

                                        <v-col cols="12" md="12"
                                            style="margin-top:10px!important;font-weight:bold;margin-left:10px">
                                            Verify Mail And Mobile
                                        </v-col>

                                    </v-row>

                                    <v-row>

                                        <v-col cols="12" md="12">

                                            <v-radio-group v-model="valueSelected">

                                                <v-radio label="E-Mail" value="E"></v-radio>
                                                <v-radio label="Mobile" value="M"></v-radio>

                                            </v-radio-group>


                                        </v-col>

                                    </v-row>



                                    <v-row>

                                        <v-col cols="12" md="12">

                                            <div style="display:flex;justify-content:center">

                                                <v-img alt="" class="btn-cancel-verify" contain
                                                    src="@/assets/btn_cancel3_alertmsg.png" max-width="30"
                                                    max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                                    transition="scale-transition" title="Annulla"
                                                    @click="btnCancelVerify" />

                                                <v-img alt="" class="btn-confirm-verify" contain
                                                    src="@/assets/btn_confirm3_alertmsg.png" max-width="30"
                                                    max-height="30" style="cursor:pointer" transition="scale-transition"
                                                    title="Conferma" @click="btnConfirmVerify" />


                                            </div>

                                        </v-col>

                                    </v-row>

                                </modal>
                            </template>

                        </div>




                    </div>
                </template>

            </div>

        </template>

        <template>
            <modal name="popupAcceptRead" :clickToClose="false" :width="350" :height="180">

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important">

                        <div style="font-size:12px;padding-left:7px;font-weight:bold;float:left">Acceptance</div>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <div style="padding-left:7px;margin-top:-25px">

                            <v-checkbox label="I declare that I accept the reading of the message" class="cl-checkbox"
                                @click="acceptReadNew()"></v-checkbox>

                        </div>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <div style="display:flex;justify-content:center">

                            <v-img alt="" class="btn-cancel-accept-read" contain src="@/assets/btn_cancel3_alertmsg.png"
                                max-width="30" max-height="30" style="cursor:pointer;color:black"
                                transition="scale-transition" />

                        </div>

                    </v-col>

                </v-row>

            </modal>
        </template>

        <template>

            <div class="contpopupsearch2" style="display:none">

                <modal name="popupSearch2" :clickToClose="false" :width="262" :height="215">


                    <v-row>



                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <img alt="" src="@/assets/alertmsg_ricerca3.png"
                                style="    max-width: 25px;
                                                                                                                                                                                                                                        min-width: 25px;
                                                                                                                                                                                                                                        position: absolute;
                                                                                                                                                                                                                                        left: 10px;" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_alertmsg.png"
                                    style="cursor:pointer;color:black;margin-right:10px;max-width:30px;min-width:30px"
                                    transition="scale-transition" title="Annulla" />

                                <img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_alertmsg.png"
                                    style="cursor:pointer;max-width:30px;min-width:30px" transition="scale-transition"
                                    title="Conferma" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>

            </div>


        </template>


        <template>

            <div class="contpopupotpread" style="display:none">

                <template>
                    <modal name="popupOtpRead" :clickToClose="false" :width="262" :height="215">


                        <v-row>

                            <v-col cols="12" md="12"
                                style="margin-top:10px!important;font-weight:bold;text-align: left;margin-left: 10px;">

                                Codice OTP inviatoLe via mail

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="12">

                                <v-text-field type="password" v-model="valueOtpRead" label="" id="fldValueOtpRead"
                                    class="inpValueOtpRead">
                                </v-text-field>

                            </v-col>

                        </v-row>



                        <v-row>

                            <v-col cols="12" md="12">

                                <div style="display:flex;justify-content:center">

                                    <v-img alt="" class="btn-cancel-otp-delete" contain
                                        src="@/assets/btn_cancel3_alertmsg.png" max-width="30" max-height="30"
                                        style="cursor:pointer;color:black;margin-right:10px"
                                        transition="scale-transition" title="Annulla" @click="btnCancelOtpRead" />

                                    <v-img alt="" class="btn-confirm-otp-delete" contain
                                        src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                        style="cursor:pointer" transition="scale-transition" title="Conferma"
                                        @click="btnConfirmOtpRead" />


                                </div>

                            </v-col>

                        </v-row>

                    </modal>
                </template>

            </div>



        </template>


        <template>


            <div class="contstati">

                <template>
                    <div class="text-center">

                        <v-bottom-sheet v-model="sheetStatus">
                            <v-sheet class="text-center" height="431px" style="padding-bottom:100px;">

                                <div style="text-align:right!important">

                                    <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">

                                        <v-img alt="" src="@/assets/btn_cancel3_alertmsg.png" max-width="24"
                                            max-height="24" style="margin-right:10px" />

                                    </v-btn>

                                </div>

                                <div class="py-3 c_title_stati">
                                    Status
                                </div>

                                <v-list-item v-for="tile in allStatus" :key="tile.title" @click="filterStatus(tile.id)">
                                    <v-list-item-avatar>

                                        <v-avatar size="25px" tile>

                                            <v-img alt="" contain :src="tile.img" :max-width="tile.width"
                                                :max-height="tile.height" />

                                        </v-avatar>

                                    </v-list-item-avatar>
                                    <v-list-item-title style="text-align:left!important;font-size:9px!important">{{
                                        tile.title
                                        }}</v-list-item-title>
                                </v-list-item>

                            </v-sheet>
                        </v-bottom-sheet>

                    </div>
                </template>

            </div>

        </template>


        <template>

            <div class="contaziende">

                <template>
                    <div class="text-center">
                        <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                            <v-sheet class="text-center" height="100vh">
                                <div class="headerSheetAziende" fixed style="position:fixed;">
                                    Aziende
                                    <v-btn class='closeSheet align_close_sheet' icon
                                        @click="sheetAziende = !sheetAziende">

                                        <v-img alt="" src="@/assets/btn_cancel3_alertmsg.png" max-width="24"
                                            max-height="24" style="margin-right:10px" />

                                    </v-btn>

                                    <v-toolbar>
                                        <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                            v-model="search" id="fldTextSearchAzi">
                                        </v-text-field>

                                    </v-toolbar>

                                </div>

                                <v-divider></v-divider>

                                <div class="spacer" style="min-height:72px;"></div>

                                <v-flex xs12 sm12>
                                    <v-card>

                                        <v-list-item class="text-left" v-for="item in filteredAziende"
                                            :key="item.companyid" @click="manageClickAziende(item)">

                                            <v-img alt="" src="@/assets/aziende-alertmsg.png" max-width="24"
                                                max-height="24" />

                                            <v-list-item-title class='itemAzienda'>{{ item.fld_name
                                                }}</v-list-item-title>

                                        </v-list-item>
                                    </v-card>
                                </v-flex>
                            </v-sheet>
                        </v-bottom-sheet>
                    </div>
                </template>
            </div>


        </template>


        <template>

            <div class="contpopupviewfilepdf" style="display:none">

                <template>
                    <modal name="popupViewFilePdf" id="pViewFilePdf" :clickToClose="false" :width="1651" :height="600">


                        <v-row>

                            <v-col cols="12" md="12"
                                style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                                Vedi e firma allegato

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="12" style="display: flex;
justify-items: center;
justify-content: center;
align-items: center;">

                                <div style="width: 94%;height: 50vh;overflow-y: auto;overflow-x: auto;">

                                    <div id="container-view-pdf"></div>

                                </div>


                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="12">

                                <div style="display:flex;justify-content:center">

                                    <v-img alt="" class="btn-cancel-otp-delete" contain
                                        src="@/assets/next-page-alertmsg.png" max-width="30" max-height="30"
                                        style="cursor:pointer;color:black;margin-right:10px"
                                        transition="scale-transition" title="Cancel" @click="btnNextViewFilePdf" />


                                </div>

                            </v-col>

                        </v-row>

                    </modal>
                </template>

            </div>

        </template>

        <template>

            <div class="contpopupsignatureviewfile" style="display:none">

                <template>
                    <modal name="popupSignatureViewFile" :clickToClose="false" :width="600" :height="322">

                        <v-row style="margin-bottom: 0px;">

                            <v-col cols="12" md="12" class="titleSignature">
                                FIRMA PER ACCETTAZIONE
                            </v-col>

                        </v-row>


                        <v-row style="margin-top: 0px;">

                            <v-col cols="12" md="12" style="text-align: center;">

                                <VueSignaturePad width="550px" height="200px" ref="signaturePad"
                                    class="contSignature" />

                            </v-col>

                        </v-row>



                        <v-row style="padding-top: 0px!important;">

                            <v-col cols="12" md="12" style="padding-top: 0px!important;">

                                <div style="display:flex;justify-content:center">



                                    <v-img alt="" class="btn-delete-signed" contain
                                        src="@/assets/icon_delete_alertmsg.png" max-width="30" max-height="30"
                                        style="cursor:pointer;color:black;margin-right:10px"
                                        transition="scale-transition" title="Pulisci"
                                        @click="btnResetSignatureViewFile" />


                                    <v-img alt="" class="btn-confirm-signed" contain
                                        src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                        style="cursor:pointer" transition="scale-transition" title="Conferma"
                                        @click="btnConfirmSignatureViewFile" />





                                </div>

                            </v-col>

                        </v-row>

                    </modal>
                </template>

            </div>

        </template>


        <template>


            <div class="contpopupotplogin" style="display:none">

                <template>
                    <modal name="popupOtpLogin" :clickToClose="false" :width="262" :height="221">


                        <v-row>

                            <v-col cols="12" md="12"
                                style="margin-top:10px!important;font-weight:bold;font-size:12px;text-align: center;">

                                OTP code sent to you via SMS

                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="12">

                                <v-text-field type="text" v-model="valueOtpLogin" label="" id="fldValueOtpLoginPrjweb">
                                </v-text-field>



                            </v-col>

                        </v-row>

                        <v-row v-if="showResend">

                            <v-col cols="12" md="12" style="display: flex;
            justify-content: center;
            padding-top: 0px!important;
            margin-top: 0px!important;
            font-size:12px">


                                <div style="font-weight: bold;
            cursor: pointer;" class="clReinviaOtp">Resend</div>

                            </v-col>

                        </v-row>


                        <v-row>

                            <v-col cols="12" md="12">

                                <div style="display:flex;justify-content:center">


                                    <v-img alt="" class="btn-confirm-otp-login" contain
                                        src="@/assets/btn_confirm3_alertmsg.png" max-width="30" max-height="30"
                                        style="cursor:pointer" transition="scale-transition" title="Confirm"
                                        @click="btnConfirmOtpLogin" />


                                </div>

                            </v-col>

                        </v-row>

                    </modal>
                </template>

            </div>



        </template>


        <template>
            <modal name="popupTest" :clickToClose="false" :width="350" :height="180">

                <v-row>

                    <v-col cols="12" md="12" style="margin-top:10px!important">

                        <div style="font-size:12px;padding-left:7px;font-weight:bold;float:left">Acceptance</div>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <div style="padding-left:7px;margin-top:-25px">


                            <v-text-field v-model="defaultItem.title" label="Subject">
                            </v-text-field>



                        </div>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" md="12">

                        <div style="display:flex;justify-content:center">

                            <v-img alt="" class="btn-cancel-accept-read" contain src="@/assets/btn_cancel3_alertmsg.png"
                                max-width="30" max-height="30" style="cursor:pointer;color:black"
                                transition="scale-transition" />

                        </div>

                    </v-col>

                </v-row>

            </modal>
        </template>



    </v-container>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";
import apiotp from "../utils/otp/apiotp";
import apiusers from "../utils/users/apiusers";
import apimessaggi from "../utils/messaggi/apimessaggi";


import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';


import {
    bus
} from "../main";
import router from ".././router";
import _ from 'lodash';

import Vue from "vue";



import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);


export default {


    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        this.$root.$children[0].checkLogout();


        this.$root.$children[0].verifyTrialPeriod();
        this.$root.$children[0].loadLogo();


        console.log("IS_DEMO: ", this.$root.$children[0].demo_version);

        window.curSrcText = "";


        window.companyDest = "-1";



        window.myVal = "";



        bus.$off("bottomIconClickEvent");

        window.perpage = 50;

        window.numEventSelRow = 0;

        window.curMsgIsSigned = 0;

        window.curMsgIsEnableSigned = 0;

        window.curStatus = 0; // default non letti

        var pointerVue = this;

        this.dipendenti = this.$root.$children[0].dipendenti;

        this.gruppi = this.$root.$children[0].gruppi;

        //this.aziende = this.$root.$children[0].aziende;


        console.log("AZIENDE: ", this.aziende);


        this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        // this.aziende = this.$root.$children[0].globalArrAziende;

        $(window).resize(function () {


            pointerVue.centerPopupViewFilePdf();


        });

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '#btnRefresh', function (event) {
                event.preventDefault();

                console.log("REFRESH");

                pointerVue.getDataFromApi();

            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();

            });


        });

        setTimeout(() => {

            this.syncAziende();


            this.$root.$children[0].loadLogo();


        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;

            var that = this;

            console.log(data);
            switch (data) {


                case "btn_view_pdf":
                    this.sheetSignature = true;

                    $("canvas").hide();
                    $("canvas").show();



                    setTimeout(() => {

                        this.$refs.signaturePad.undoSignature();
                        this.$refs.signaturePad.openSignaturePad();
                        this.$refs.signaturePad.clearSignature();


                        window.dispatchEvent(new Event('resize'));





                    }, 300);


                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));


                        var canvas = document.getElementsByTagName("canvas")[1];

                        var date = new Date();

                        var month = date.getMonth() + 1;
                        if (month.toString().length == 1) month = "0" + month.toString();

                        let formatted_date = date.getDate() + "-" + month + "-" + date.getFullYear()

                        var ctx = canvas.getContext("2d");
                        ctx.font = "bold 12px Arial";
                        ctx.fillText(formatted_date + " Per accettazione", 5, 10);

                    }, 1000);


                    break;

                case "btn_download":


                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;

                    setTimeout(() => {



                        console.log("CUR ITEM: ", this.currItemRow);

                        var path_file = "";

                        if (this.currItemRow.doc_cond == 0) {


                            // path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/" + this.currItemRow.attachment;

                            path_file = "s3/aziende/" + this.currItemRow.val_cmp_dest + "/Products/Alertmsg/" + this.currItemRow.attachment;

                        }

                        if (this.currItemRow.doc_cond == 1) {

                            path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/Condivisi/" + this.currItemRow.attachment;

                        }



                        console.log("PATH FILE DOWN: ", path_file);


                        var url_call = that.getUrlDomain("AlertMsgDownAttach") + "?f=" + path_file;

                        console.log("URL TO CALL: ", url_call);

                        window.open(url_call, "_blank");


                        setTimeout(() => {

                            that.showSpinner = false;
                            that.$root.$children[0].showProgress = false;

                        }, 1000);


                    }, 500);


                    break;


                case "btn_download2":


                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;

                    setTimeout(() => {



                        console.log("CUR ITEM: ", this.currItemRow);

                        var path_file = "";

                        if (this.currItemRow.doc_cond == 0) {

                            path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/" + this.currItemRow.attachment2;

                        }

                        if (this.currItemRow.doc_cond == 1) {

                            path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/Condivisi/" + this.currItemRow.attachment2;

                        }



                        console.log("PATH FILE DOWN: ", path_file);


                        var url_call = that.getUrlDomain("AlertMsgDownAttach") + "?f=" + path_file;

                        console.log("URL TO CALL: ", url_call);

                        window.open(url_call, "_blank");


                        setTimeout(() => {

                            that.showSpinner = false;
                            that.$root.$children[0].showProgress = false;

                        }, 1000);


                    }, 500);


                    break;

                case "btn_download3":


                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;

                    setTimeout(() => {



                        console.log("CUR ITEM: ", this.currItemRow);

                        var path_file = "";

                        if (this.currItemRow.doc_cond == 0) {

                            path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/" + this.currItemRow.attachment3;

                        }

                        if (this.currItemRow.doc_cond == 1) {

                            path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/Condivisi/" + this.currItemRow.attachment3;

                        }



                        console.log("PATH FILE DOWN: ", path_file);


                        var url_call = that.getUrlDomain("AlertMsgDownAttach") + "?f=" + path_file;

                        console.log("URL TO CALL: ", url_call);

                        window.open(url_call, "_blank");


                        setTimeout(() => {

                            that.showSpinner = false;
                            that.$root.$children[0].showProgress = false;

                        }, 1000);


                    }, 500);


                    break;



                case "btn_delete":
                    this.delMsg();
                    break;

                case "btn_delete_row":
                    this.delMsgRow();
                    break;

                case "btn_invia_msg":
                    this.sendMsg();
                    break;

                case "btn_invia_msg_demo":

                    this.sendMsgDemo();

                    break;

                case "btn_companies":
                    //  this.setupButtons();
                    this.sheetAziende = true;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.sheetGruppi = false;
                    this.dialogMsgSend = false;
                    break;

                case "btn_dipendenti":
                    //  this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = true;
                    this.sheetGruppi = false;
                    this.dialogMsgSend = false;
                    break;

                case "btn_gruppi":

                    this.gruppi = this.$root.$children[0].gruppi;

                    //  this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.sheetGruppi = true;
                    this.dialogMsgSend = false;
                    break;

                case "btn_back":
                    this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetGruppi = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.dialogMsgSend = false;
                    this.dialogMsg = false;
                    this.closeSendMsg();
                    break;

                case "btn_back_demo":
                    this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetGruppi = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.dialogMsgSend = false;
                    this.dialogMsg = false;
                    this.dialogMsgSendDemo = false;
                    this.closeSendMsgDemo();
                    break;


                case "btn_aziende":

                    this.buttonsNewMessage();

                    this.dipendenti = this.$root.$children[0].dipendenti;
                    this.aziende = this.$root.$children[0].aziende;
                    this.globalArrAziende = this.$root.$children[0].globalArrAziende;

                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {
                        this.sheetAziende = true;
                        this.$root.$children[0].showProgress = false;

                    }, 300);

                    break;


                case "btn_aziende_demo":



                    router.push({
                        path: "/sendMessageTitleMessage"
                    });


                    break;

                case "btn_add_tms":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_view":


                    var ele_sel = null;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            ele_sel = $(this);

                        }

                    });


                    var v_row = ele_sel.parent().parent();

                    console.log("ROW SEL: ", v_row);

                    var item2 = window.table.row(v_row).data();

                    console.log("ITEM ROW: ", item2);

                    this.currItemRow = item2;


                    console.log("CUR ITEM ROW: ", this.currItemRow);

                    if (this.currItemRow.read == 0) {

                        this.$modal.show('popupAcceptRead');
                    } else {

                        this.handleClick(this.currItemRow);

                    }

                    break;



                case "btn_resend":

                    this.resendMsg();

                    break;

                case "btn_view_and_sign":

                    window.currentItemRow = this.currItemRow;

                    router.push({
                        path: "/vediAllegato"
                    });

                    break;


                case "btn_view_document":

                    window.currentItemRow = this.currItemRow;

                    router.push({
                        path: "/vediDocumento"
                    });

                    break;


                case "btn_verify":



                    this.openPopupVerify();


                    break;


                default:
                    break;
            }
        });

        this.getLastLogin();



        this.setupButtons();

        this.setupGrid();

    },
    data: () => ({

        valueSelected: "E",

        showResend: false,

        valueOtpLogin: "",


        pdfUrl: "",


        allStatus: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'All',
                "id": -1,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 25,
                height: 25
            },


            {
                img: 'https://app.emtool.eu/public/_lib/img/red_cross_icon_2.png',
                title: 'Unread',
                "id": 0,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 15,
                height: 15
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/green_mark_ok.png',
                title: 'Read',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 15,
                height: 15
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/red_cross_icon_2.png',
                title: 'Unseigned',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 15,
                height: 15
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/green_mark_ok.png',
                title: 'Signed',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red",
                width: 15,
                height: 15
            },

        ],


        sheetStatus: false,

        valueOtpRead: "",

        textSearch: "",

        definput: { name: "", surname: "", mailaddress: "", titolo: "", testo: "" },

        pdfSource: "",
        page: 1,

        currentFile: null,
        selecting: 0,
        currItem: {},
        currItemRow: {},
        currGruppo: {},
        switch1: true,
        nomeCorrente: "",
        nomeCompletoCorrente: "",
        titolo: "",
        body: "",
        mailaddress: "",
        enablesignature: false,
        aziende: [],
        gruppi: [],
        globalArrAziende: [],
        dipendenti: [],
        search: '',
        sheetGruppi: false,
        sheetSignature: false,
        sheetAziende: false,
        sheetEmpty: false,
        sheetDipendenti: false,
        searchDipendenti: '',
        searchGruppi: '',

        currAzienda: "",

        defaultItem: {
            title: 'ti',
            body: '',
            read: 0,
            accepted: 0,
            id: 0

        },
        defaultItemSend: {
            title: '',
            body: '',

        },

        dialogMsgSend: false,
        dialogMsg: false,
        dialogMsgSendDemo: false,

        singleSelect: true,

        totalDesserts: 0,
        desserts: [],
        loading: true,
        options: {},
        headers: [{
            text: ' ',
            value: 'read',
            align: 'start'
        },
        {
            text: 'Date',
            value: 'created_at',
            align: 'start'
        },
        {
            text: 'Title',

            sortable: true,
            value: 'title',
            align: 'start'
        },
        {
            text: 'Receiver',

            sortable: true,
            value: 'dest',
            align: 'start'
        },

        ],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),



    methods: {

        btnConfirmVerify: function () {

            var tot = 0;
            var check_mail = 0;
            var check_mobile = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    check_mail = $(this).attr("check-mail")
                    check_mobile = $(this).attr("check-mobile")

                }

            });

            console.log("CHECK EMAIL: ", check_mail);
            console.log("CHECK MOBILE: ", check_mobile);
            console.log("SEL VALUE: ", this.valueSelected);


            if (this.valueSelected == "E") {

                if (check_mail == 1) {
                    this.$swal({
                        icon: "error",
                        text: "Email already verified",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }
                if (check_mail == 0) {

                    router.push({
                        path: "/verifyEmailAndMobile"
                    });


                }

            }

            if (this.valueSelected == "M") {

                if (check_mobile == 1) {
                    this.$swal({
                        icon: "error",
                        text: "Mobile already verified",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }
                if (check_mobile == 0) {

                    console.log("ok");

                    router.push({
                        path: "/verifiyMobile"
                    });

                }

            }

        },

        btnCancelVerify: function () {

            this.$modal.hide('popupVerify');


        },

        openPopupVerify: function () {

            $(".contpopupverify").show();

            this.$modal.show('popupVerify');

        },

        btnConfirmOtpLogin: function () {


            if (this.valueOtpLogin != "") {

                console.log("OTP INP: ", this.valueOtpLogin);
                console.log("ENC OTP INP: ", btoa(this.valueOtpLogin));

                if (window.myVal == btoa(this.valueOtpLogin)) {

                    this.$modal.hide('popupOtpLogin');


                    //this.loginAction2(this.input.username);


                }
                else {


                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        this.valueOtpLogin = "";
                        $("#fldValueOtpLoginPrjweb").focus();

                    }, 2500);


                }



            }
            else {


                $("#fldValueOtpLoginPrjweb").focus();

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },



        btnNextViewFilePdf: function () {

            // this.$modal.hide('popupViewFilePdf');

            // $(".contpopupsignatureviewfile").show();

            // this.$modal.show('popupSignatureViewFile');



        },


        btnConfirmSignatureViewFile: function () {

            var errore = "";

            if (this.$refs.signaturePad.isEmpty()) {
                errore = "La firma è richiesta";
            }

            if (errore == "") {

                console.log("ok");

                // this.saveSignedDocument();

                this.getDataForOtp();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }



        },


        getValueForOtp: async function (v_username, v_name_product, v_mobile, v_prefix_mobile) {

            console.log(v_username);
            console.log(v_name_product);
            console.log(v_mobile);
            console.log(v_prefix_mobile);

            var that = this;

            ///$(".contpopuptest").show();

            that.$modal.show('popupTest');



            // var that = this;

            // that.$modal.hide('popupSignatureViewFile');

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apimessaggi.getValueForOtp(
            //     v_username,
            //     v_name_product,
            //     v_mobile,
            //     v_prefix_mobile
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from getValueForOtp", res);

            //     if (res.data.Result == "OK") {

            //         console.log("ok");

            //         window.myVal = res.data.ValReturn;


            //         setTimeout(() => {

            //             that.showResend = true;

            //             that.valueOtpLogin = "";

            //             $(".contpopupotplogin").show();

            //             that.$modal.show('popupOtpLogin');



            //             setTimeout(() => {

            //                 $("#fldValueOtpLoginPrjweb").focus();

            //             }, 300);

            //         }, 200);

            //     }
            //     else {

            //         that.$swal({
            //             icon: "error",
            //             text: res.data.ErrDetails,
            //             showConfirmButton: false,
            //             timer: 3000
            //         });
            //     }



            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("Errori", "Non è stato possibile eliminare i dati");
            //     console.log("response", response);

            // }

            // );


        },





        getDataForOtp: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getDataForOtp(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDataForOtp", res);

                if (res.data.Result == "OK") {

                    console.log("ok");

                    that.getValueForOtp(res.data.Username, "emt", res.data.Mobile, res.data.Prefix);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },




        saveSignedDocument: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.$modal.hide('popupSignatureViewFile');

            const { isEmpty, data } = that.$refs.signaturePad.saveSignature();
            console.log(isEmpty);
            console.log("data:", data);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.saveSignedDocument(
                that.currItem.id,
                data,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveSignedDocument", res);

                if (res.data.Result == "OK") {

                    // that.$swal({
                    //     icon: "success",
                    //     text: "Documento firmato correttamente, una e-mail Le è stata inviata",
                    //     showConfirmButton: false,
                    //     timer: 2000
                    // });

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },




        btnResetSignatureViewFile: function () {

            this.$refs.signaturePad.undoSignature();

        },


        btnCancelSignatureViewFile: function () {

            this.$modal.hide('popupSignatureViewFile');

        },


        centerPopupViewFilePdf: function () {


            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFilePdf').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },


        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('container-view-pdf');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },




        viewAttachment: async function () {

            var that = this;

            var path_file = "";

            if (that.currItemRow.doc_cond == 0) {

                path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/" + that.currItemRow.attachment;

            }

            if (that.currItemRow.doc_cond == 1) {

                path_file = "s3/aziende/LC-SERVICE SRL/Products/Alertmsg/Condivisi/" + that.currItemRow.attachment;

            }

            console.log("PATH FILE DOWN: ", path_file);

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimessaggi.getAttachment(
                path_file,
                v_token,
                that.currItemRow.attachment
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAttachment", res);

                setTimeout(() => {


                    $(".contpopupviewfilepdf").show();

                    that.$modal.show('popupViewFilePdf');

                    that.pdfUrl = res.data.PathReturn;

                    setTimeout(() => {


                        that.loadPdf();

                        that.centerPopupViewFilePdf();


                    }, 200);



                }, 100);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "alertmsg"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = "(" + res.data.Result + ")";


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token,
                "alertmsg"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                that.aziende = res.data.filtro_aziende;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        filterStatus: async function (status) {
            console.log(status);

            this.sheetStatus = false;

            window.curStatus = status;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(() => {

                window.table.ajax.reload();

            }, 200);



        },



        getOtpRead: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");



            window.myVal = "";


            that.valueOtpRead = "";


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMail(
                v_token,
                'alertmsg',
                'conf_lett_msg',
                that.currItemRow.message_id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMail", res);

                window.myVal = res.data.Result;



                setTimeout(() => {

                    $(".contpopupotpread").show();

                    this.$modal.show('popupOtpRead');


                    setTimeout(() => {

                        $("#fldValueOtpRead").val("");

                        $("#fldValueOtpRead").focus();

                    }, 300);





                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },



        btnCancelOtpRead: function () {

            this.$modal.hide('popupOtpRead');


        },

        doUpdateMessage: function () {

            var ele_sel = null;

            var v_id_msg = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_id_msg = $(this).attr("message-id");

                }

            });


            console.log("ELE SEL: ", ele_sel);
            console.log("VAL ID: ", v_id_msg);

            this.updateMessage(v_id_msg);

            this.currItemRow.read = 1;

            this.handleClick(this.currItemRow);

        },

        btnConfirmOtpRead: function () {


            if (this.valueOtpRead != "") {

                console.log("OTP INP: ", this.valueOtpRead);
                console.log("ENC OTP INP: ", btoa(this.valueOtpRead));

                if (window.myVal == btoa(this.valueOtpRead)) {


                    this.$modal.hide('popupOtpRead');


                    var ele_sel = null;

                    var v_id_msg = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            v_id_msg = $(this).attr("message-id");

                        }

                    });


                    console.log("ELE SEL: ", ele_sel);
                    console.log("VAL ID: ", v_id_msg);

                    //this.updateMessage(this.currItemRow.message_id);
                    this.updateMessage(v_id_msg);

                    this.currItemRow.read = 1;

                    this.handleClick(this.currItemRow);

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }




        },


        undo() {
            this.$refs.signaturePad.undoSignature();
        },

        saveData: async function () {


            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            console.log(isEmpty);
            console.log("data:", data);

            this.$swal({
                icon: "success",
                text: "Your message has been signed correctly",
                showConfirmButton: false,
                timer: 2000
            });



            //Start 
            var response;
            var token = window.$cookies.get("token");

            var id_msg = this.currItemRow.message_id;

            // console.log(username, token_system);
            try {
                response = this.$api.updateStatusMsgApiSignature(
                    token,
                    id_msg,
                    data
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }
            console.log(response);

            //END

            this.$swal({
                icon: "success",
                text: "Your message has been signed correctly",
                showConfirmButton: false,
                timer: 2000
            });


            // here
            this.sheetSignature = false;
            this.setupButtons();

            setTimeout(() => {
                //window.table.ajax.reload();
                window.table.ajax.reload();

            }, 300);

        }
        ,

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/" + nome_metodo;

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },





        getUrlAttachment: function (nome_file) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/documents/" + nome_file;

        },



        setupGrid: function () {

            var pointerVue = this;

            pointerVue.$root.$children[0].totalMsgBaseRis = 1;
            pointerVue.$root.$children[0].totalMsgRefs = 0;

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            var filtri = ``;
            if ((a == 'S') || (a == 'Y') || (b == 'S') || (b == 'Y')) {

                filtri = `<img src='/alertmsg/img/update-left-rotation-alertmsg.png' style='border:0!important;' class='selaggiorna' title='Refresh' /><img src='/alertmsg/img/filtro-stato-alertmsg.png' style='border:0!important;' class='selstato' title='Status' /><img src='/alertmsg/img/aziende-alertmsg.png' style='border:0!important;' class='selazienda' title='Company' />`;

            }
            else {

                filtri = `<img src='/alertmsg/img/update-left-rotation-alertmsg.png' style='border:0!important;' class='selaggiorna' title='Refresh' /><img src='/alertmsg/img/filtro-stato-alertmsg.png' style='border:0!important;' class='selstato' title='Status' />`;

            }



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        name: 'sel_row',
                        width: '1%'
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: true,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'read_dest',
                        orderable: false,
                        name: 'read_dest',
                        width: '1%'
                    },

                    {
                        data: 'check_mail',
                        orderable: false,
                        name: 'check_mail',
                        width: '3%'
                    },

                    {
                        data: 'check_mobile',
                        orderable: false,
                        name: 'check_mobile',
                        width: '3%'
                    },

                    {
                        data: 'doc_signature',
                        orderable: false,
                        name: 'doc_signature',
                        width: '1%',
                    },

                    {
                        data: 'created_at',
                        orderable: true,
                        name: 'created_at',
                        width: '1%',

                        render: function (data, row) {
                            console.log(data, row);

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            var html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                            return html;
                        }

                    },

                    {
                        data: 'title_formatted',
                        orderable: true,
                        name: 'title_formatted',
                        width: '5%',
                    },

                    {
                        data: 'cmp_mitt',
                        orderable: true,
                        name: 'cmp_mitt',
                        width: '5%',
                    },

                    {
                        data: 'dest_formatted',
                        orderable: true,
                        name: 'dest_formatted',
                        width: '4%'
                    },

                    {
                        data: 'surname_dest',
                        orderable: true,
                        name: 'surname_dest',
                        width: '5%',
                    },



                    {
                        data: 'cmp_dest',
                        orderable: true,
                        name: 'cmp_dest',
                        width: '5%',
                    },


                    {
                        data: 'attach_present',
                        orderable: false,
                        name: 'attach_present',
                        width: '5%',
                        render: function (data, type, row) {
                            //console.log ( row,data, type, row );
                            console.log("OK", data, type, row);

                            if (row != undefined) {

                                if (row.attachment.length > 0)
                                    return "<div class='att' data-id='" + "https://app.alertmsg.eu/public/documents/" + row.attachment + "'>" + data + "</div>";
                                else
                                    return data;


                            }
                            else
                                return data;



                        }
                    },



                ];

                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,


                    ajax: {

                        url: pointerVue.getUrlDomainApi("newGetMessagesApi") + '?username=' + v_token + "&productid=alertmsg&type_msg=1",

                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;

                            valori.companyDest = window.companyDest;

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                    }

                });

                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();


                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomainApi("newGetMessagesApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    //   $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Messages</div>
                    ` + filtri + `

                </div>
                <div><img src='/alertmsg/img/cerca-alertmsg.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);



                $('body').on('click', '.btn-confirm-search', function () {

                    console.log("ok");

                    window.curSrcText = pointerVue.textSearch;

                    pointerVue.$modal.hide('popupSearch2');

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();


                });

                $('body').on('click', '.btn-cancel-search', function () {

                    console.log("ok");

                    pointerVue.$modal.hide('popupSearch2');

                });



                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch2").show();

                    pointerVue.$modal.show('popupSearch2');

                });

                $(".selstato").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetStatus = true;


                });


                $(".selazienda").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetAziende = true;


                });



                $('body').on('click', '.selaggiorna', function () {

                    console.log("EVENT SELAGGIORNA");


                    setTimeout(() => {

                        pointerVue.hideShowButtons(false);

                        window.table.ajax.reload();


                    }, 200);

                });

                $('body').on('click', '.cont_messaggi_base_users tr', function () {

                    console.log("ACTIVE-ROW");

                    if (window.numEventSelRow == 0) {

                        console.log("ACTIVE-ROW OK");

                        window.numEventSelRow = window.numEventSelRow + 1;

                        setTimeout(() => {

                            var row_data = window.table.row(this).data();

                            console.log("ROW DATA: ", row_data);

                            if (row_data != undefined) {

                                var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                                var id_sel = $(this).find(".id_row").attr("data-id");
                                var is_signatured = $(this).find(".id_row").attr("is-signatured");
                                var en_signature = $(this).find(".id_row").attr("en-signature");

                                console.log("ID SEL: ", id_sel);
                                console.log("IS SIGN: ", is_signatured);
                                console.log("EN SIGN: ", en_signature);


                                window.curMsgIsSigned = is_signatured;

                                window.curMsgIsEnableSigned = en_signature;


                                //$("tr").removeClass("active-row");

                                var set_cls_active = 0;

                                if (last_id_sel == "undefined") {
                                    set_cls_active = 1;
                                } else {
                                    if (last_id_sel != id_sel) {
                                        set_cls_active = 1;
                                    }
                                }

                                var item = window.table.row(this).data();


                                console.log("ITEM: ", item);

                                if (item != undefined) {


                                    pointerVue.currItemRow = item;

                                    var link = null;
                                    if (item.attachment) {

                                        link = "https://app.alertmsg.eu/public/documents/" + item.attachment;


                                    }
                                    else {
                                        pointerVue.pdfSource = link;
                                    }



                                    setTimeout(() => {
                                        pointerVue.pdfSource = link;
                                    }, 0)


                                    console.log(link);



                                }

                                if (set_cls_active == 1) {

                                    console.log("ACTIVE");

                                    // $(this).addClass("active-row");

                                    // pointerVue.hideShowButtons(true);

                                } else {

                                    console.log("NOT ACTIVE");

                                    // pointerVue.hideShowButtons(false);

                                }

                                console.log("THIS: ", this);

                                var hasClassActive = $(this).hasClass("active-row");

                                if (hasClassActive) {

                                    console.log("ACTIVE-ROW");

                                    $(this).find(".val_status").prop("checked", false);

                                    $(this).removeClass("active-row");

                                }

                                if (!hasClassActive) {

                                    console.log("NOT ACTIVE-ROW");

                                    $(this).find(".val_status").prop("checked", true);

                                    $(this).addClass("active-row");



                                }

                                pointerVue.hideShowButtonsSelRow();




                            }



                        }, 100);

                    }



                });

                $('body').on('click', '.btn-cancel-accept-read', function (event) {
                    console.log(event);

                    pointerVue.$modal.hide('popupAcceptRead');

                });


                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);

                        pointerVue.currItemRow = item;


                    }

                    if (!checked) {

                        $(v_row).removeClass("active-row");

                    }

                    pointerVue.hideShowButtonsSelRow();

                });


            });

        },

        selectFile(file) {
            // this.progress = 0;
            this.currentFile = file;
        },

        alerta: function (val) {
            this.selecting = 1;
            console.log(val);
            setTimeout(() => {

                this.selecting = 0;
            }, 300);
        },
        acceptRead: function () {

            this.updateMessage(this.currItem.message_id);

            $("tr.active-row").find(".msgread0").removeClass("msgread0");
        },

        acceptReadNew: function () {

            console.log("ok");

            this.$modal.hide('popupAcceptRead');

            this.doUpdateMessage();


        },

        addBackDeleteBtn: function () {

            var a2 = window.$cookies.get("a");

            console.log(a2);


            var pulsantis = [];
            pulsantis.push({
                text: "",
                icon: "mdi-arrow-left",
                link: "/back",
                id: "btn_back",
                disabled: false,
                title: "Back",
                image: "https://app.emtool.eu/public/_lib/img/back-alertmsg.png",
                width: 35

            });


            if ((this.currItem.attachment != null) || (this.currItem.attachment2 != null) || (this.currItem.attachment3 != null)) {


                if ((this.currItem.attachment != null) && (this.currItem.attachment2 == null) && (this.currItem.attachment3 == null)) {

                    if (this.currItem.attachment != "") {

                        if ((a2 == "S") || (a2 == "Y")) {

                            pulsantis.push({
                                text: "",
                                icon: "mdi-download-outline",
                                link: "/download",
                                id: "btn_download",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/download_alertmsg.png",
                                title: "Scarica Allegato",
                                width: 20

                            });

                        }

                        if (a2 == "N") {

                            console.log("CURRENT SIGN: ", this.currItemRow.signature);


                            if ((this.currItemRow.enable_view == 1) && (this.currItemRow.enable_signature == 1)) {

                                if (this.currItemRow.signature == 0) {

                                    pulsantis.push({
                                        text: "",
                                        icon: "mdi-download-outline",
                                        link: "/download",
                                        id: "btn_view_and_sign",
                                        disabled: false,
                                        image: this.getUrlDomain("public/_lib/img/doc_alertmsg.png"),
                                        title: "Document",
                                        width: 35

                                    });


                                }

                                if (this.currItemRow.signature == 1) {



                                    if (this.currItemRow.enable_view == 1) {

                                        pulsantis.push({
                                            text: "",
                                            icon: "mdi-download-outline",
                                            link: "/download",
                                            id: "btn_view_document",
                                            disabled: false,
                                            image: this.getUrlDomain("public/_lib/img/doc_alertmsg.png"),
                                            title: "Document",
                                            width: 35

                                        });

                                    }



                                    pulsantis.push({
                                        text: "",
                                        icon: "mdi-download-outline",
                                        link: "/download",
                                        id: "btn_download",
                                        disabled: false,
                                        image: "https://app.emtool.eu/public/_lib/img/download_alertmsg.png",
                                        title: "Download",
                                        width: 20

                                    });

                                }


                            }

                            if ((this.currItemRow.enable_view == 0) && (this.currItemRow.enable_signature == 0)) {

                                pulsantis.push({
                                    text: "",
                                    icon: "mdi-download-outline",
                                    link: "/download",
                                    id: "btn_download",
                                    disabled: false,
                                    image: "https://app.emtool.eu/public/_lib/img/download_alertmsg.png",
                                    title: "Download",
                                    width: 20

                                });


                            }

                            if ((this.currItemRow.enable_view == 0) && (this.currItemRow.enable_signature == 1)) {

                                pulsantis.push({
                                    text: "",
                                    icon: "mdi-download-outline",
                                    link: "/download",
                                    id: "btn_download",
                                    disabled: false,
                                    image: "https://app.emtool.eu/public/_lib/img/download_alertmsg.png",
                                    title: "Download",
                                    width: 20

                                });


                            }

                            if ((this.currItemRow.enable_view == 1) && (this.currItemRow.enable_signature == 0)) {

                                pulsantis.push({
                                    text: "",
                                    icon: "mdi-download-outline",
                                    link: "/download",
                                    id: "btn_view_document",
                                    disabled: false,
                                    image: this.getUrlDomain("public/_lib/img/doc_alertmsg.png"),
                                    title: "Document",
                                    width: 35

                                });

                                pulsantis.push({
                                    text: "",
                                    icon: "mdi-download-outline",
                                    link: "/download",
                                    id: "btn_download",
                                    disabled: false,
                                    image: "https://app.emtool.eu/public/_lib/img/download_alertmsg.png",
                                    title: "Download",
                                    width: 20

                                });


                            }



                        }


                    }


                }



                if ((this.currItem.attachment != null) && ((this.currItem.attachment2 != null) || (this.currItem.attachment3 != null))) {

                    if (this.currItem.attachment) {

                        pulsantis.push({
                            text: "",
                            icon: "mdi-download-outline",
                            link: "/download",
                            id: "btn_download",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/download_alertmsg.png",
                            title: "Scarica Allegato 1",
                            width: 20

                        });
                    }

                    if (this.currItem.attachment2) {

                        pulsantis.push({
                            text: "",
                            icon: "mdi-download-outline",
                            link: "/download",
                            id: "btn_download2",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/download_alertmsg.png",
                            title: "Scarica Allegato 2",
                            width: 20

                        });
                    }


                    if (this.currItem.attachment3) {

                        pulsantis.push({
                            text: "",
                            icon: "mdi-download-outline",
                            link: "/download",
                            id: "btn_download3",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/download_alertmsg.png",
                            title: "Scarica Allegato 3",
                            width: 20

                        });
                    }



                }







            }


            setTimeout(() => {
                $("#btn_download").attr("href", "https://app.emtool.eu/public/documents/" + this.currItem.attachment);

            }, 300);

            var a = window.$cookies.get("a");
            if (a == 'Y' || a == 'S') {

                pulsantis.push({
                    text: "",
                    icon: "mdi-delete",
                    link: "/delete",
                    id: "btn_delete",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/delete-alertmsg.png",
                    title: "Elimina",
                    width: 30

                });
            }

            this.$root.$children[0].bnavbuttons = pulsantis;

        },
        addBackBtn: function () {

            var pulsantis = [];
            pulsantis.push({
                text: "",
                icon: "mdi-arrow-left",
                link: "/back",
                id: "btn_back",
                disabled: false,
            });


            this.$root.$children[0].bnavbuttons = pulsantis;

        },
        handleClick(row) {

            if (this.selecting != 1) {
                // set active row and deselect others
                console.log("row", row);
                this.openMsgDialog(row);
                //this.addBackBtn();
                this.addBackDeleteBtn();

                this.desserts.map((item, index) => {
                    item.selected = item === row

                    this.$set(this.desserts, index, item)
                })

                // or just do something with your current clicked row item data
                console.log(row.sugar)
            }

        },
        manageCloseSignature: function () {

            this.setupButtons();
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.sheetDipendenti = false;
            this.sheetGruppi = false;
            this.sheetSignature = false;

            this.hideShowButtons(true);

        },
        manageClose: function () {

            this.setupButtons();
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.sheetDipendenti = false;
            this.sheetGruppi = false;

        },
        changeGruppi: function () {
            this.sheetDipendenti = !this.sheetDipendenti;
            this.sheetGruppi = !this.sheetGruppi;

            /* if(!this.switch1){
                this.sheetDipendenti = false ;
                this.sheetGruppi = true ;
            }
            else
                {
                     this.sheetDipendenti = true ;
                    this.sheetGruppi = false ;
                } */
        },

        delMsg: function () {

            var response;
            console.log("this.currItem", this.currItem);
            try {
                response = this.$api.deleteMessage(
                    this.currItem.message_id

                );

                this.$swal({
                    icon: "success",
                    text: "Your message has been deleted correctly",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    this.dialogMsg = false;
                    this.setupButtons();
                }, 2000);

            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            console.log(response);

        },

        resendMsg: function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            try {
                var response = that.$api.resendMessage(
                    v_token,
                    ids
                );

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                console.log("res from resendMessage", response);

                that.$swal({
                    icon: "success",
                    text: "I messaggi sono stati reinviati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });



            } catch (error) {
                console.log("ERROR DELETE: ", error);
            }


        },

        delMsgRow: function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Sei sicuro di voler eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertmsg.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_alertmsg.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    },
                })
                .then(async function (result) {

                    if (result.value == true) {

                        console.log("that.currItemRow: ", that.currItemRow);

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {


                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);

                        try {
                            var response = that.$api.deleteMessageMulti(
                                v_token,
                                ids
                            );

                            console.log("res from deleteMessageMulti", response);

                            that.$swal({
                                icon: "success",
                                text: "Your message has been deleted correctly",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                window.table.ajax.reload();

                                that.hideShowButtons(false);

                                that.$root.$children[0].syncMsg();

                            }, 200);

                        } catch (error) {
                            console.log("ERROR DELETE: ", error);
                        }

                    }

                });



        },


        sendMsgDemo: function () {

            var name = $("#name").val();
            var surname = $("#surname").val();
            var mailaddress = $("#mailaddress").val();
            var title = $("#titolo").val();
            var body = $("#body").val();

            var that = this;

            var chkEnableSign = 0;
            // if ($(".checkEnableSignature").prop("checked")) {
            //     chkEnableSign = 1;
            // }


            var errore = "";
            if (name.length == 0) {
                errore = "Specificare il nome";
            }
            if (errore.length == 0) {
                if (surname.length == 0) {
                    errore = "Specificare il cognome";
                }
            }
            if (errore.length == 0) {
                if (mailaddress.length == 0) {
                    errore = "Specificare l'indirizzo mail";
                }
            }
            if (errore.length == 0) {
                if (title.length == 0) {
                    errore = "Specificare l'oggetto";
                }
            }
            if (errore.length == 0) {
                if (body.length == 0) {
                    errore = "Specificare il testo";
                }
            }

            console.log("ATTACHMENT: ", $('#input-file-logo')[0].files);

            this.currentFile = $('#input-file-logo')[0].files;

            console.log(chkEnableSign);

            if (errore.length == 0) {

                if ($('#input-file-logo')[0].files.length > 0) {

                    var myfile = $('#input-file-logo')[0].files[0];

                    var lfile = myfile.name.split(".");

                    console.log("MYFILE: ", lfile[lfile.length - 1]);

                    var extension = lfile[lfile.length - 1]

                    console.log("EXT: ", extension);

                    if (extension.toString().toUpperCase() != "PDF") {

                        errore = "Accettati solo file pdf";

                    }


                }

            }


            if (errore.length == 0) { // se la firma è abilitata controllo che sia stato specificato l'allegato

                if (chkEnableSign == 1) {

                    if ($('#input-file-logo')[0].files.length == 0) {


                        errore = "Specificare l'allegato";


                    }


                }

            }


            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");

            var response;


            if (errore.length == 0) {

                this.showSpinner = true;

                this.nomeCorrente = mailaddress;

                try {
                    response = this.$api.insertMessageDemo(
                        this.nomeCorrente,
                        token,
                        username,
                        title,
                        body,
                        0,
                        this.currentFile,
                        mailaddress,
                        name,
                        surname,
                        chkEnableSign
                    );

                    this.$swal({
                        icon: "success",
                        text: "Il tuo messaggio è stato inviato correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });



                    this.dialogMsgSendDemo = false;
                    this.setupButtons();

                    setTimeout(() => {

                        // window.table.ajax.reload();
                        // window.table.ajax.reload();
                        window.table.ajax.reload();

                        that.$root.$children[0].syncMsg();


                    }, 2000);





                    // this.closeSendMsgDemo();

                    // setTimeout(() => {


                    // }, 300);


                } catch (error) {
                    this.showSpinner = false;
                    console.log(error);
                }

            }

            if (errore.length > 0) {

                this.$swal({
                    icon: "error",
                    text: errore
                });

            }



            console.log(response);

        },


        sendMsg: function () {

            var title = $("#titolo").val();
            var body = $("#body").val();

            console.log("ATTACHMENT: ", $('#input-file-logo')[0].files);

            this.currentFile = $('#input-file-logo')[0].files;


            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");
            var response;

            console.log(" this.currGruppo", this.currGruppo.nome);
            var isgroup = 0;
            if (this.currGruppo.nome !== undefined) {
                isgroup = 1;

            }
            if (title.length > 0) {
                try {
                    response = this.$api.insertMessage(
                        this.nomeCorrente,
                        token,
                        username,
                        title,
                        body,
                        isgroup,
                        this.currentFile

                    );

                    this.$swal({
                        icon: "success",
                        text: "Your message has been sent correctly",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        this.closeSendMsg();

                        //this.getDataFromApi();
                    }, 2000);

                    // setTimeout(() => {
                    //     window.table.ajax.reload();

                    // }, 100);

                } catch (error) {
                    this.showSpinner = false;
                    console.log(error);
                }
            } else {
                this.$swal({
                    icon: "error",
                    text: "Please insert the title and body message"
                });

            }

            console.log(response);

        },



        changeDip: function (selectObj) {
            console.log(selectObj);
            var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    selNameDip = dip.fld_name;
                    //set display
                    this.nomeCorrente = atob(dip.v_user);

                    this.nomeCompletoCorrente = dip.fld_name;

                }
            }
            console.log(selNameDip);
            //  this.$root.$children[0].curDipTop = selNameDip;

        },

        initMsgSendDemo: function () {

            console.log("MANAGE_CLICK_DIPENDENTI");

            //   console.log(isGroup);
            this.sheetDipendenti = false;
            this.sheetGruppi = false;
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.dialogMsgSendDemo = true;


            this.definput.name = "";
            this.definput.surname = "";
            this.definput.mailaddress = "";
            this.definput.titolo = "";
            this.definput.testo = "";

            $(".checkEnableSignature").prop("checked", false);

            $('.dropify-clear').click();

            // console.log("INPUT NAME: ", this.$refs["inputname"]);


            // this.$refs.inputname.value = "";





            // if (this.currGruppo) {
            //     this.currGruppo = dipendente;
            //     this.nomeCorrente = dipendente.nome;

            //     this.nomeCompletoCorrente = this.currGruppo.nome;

            //     console.log("this.currGruppo", this.currGruppo);

            // } else {
            //     this.currGruppo = {};

            // }

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "",
                    icon: "mdi-email-send-outline",
                    link: "/invia",
                    id: "btn_invia_msg_demo",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save-alertmsg.png",
                    title: "Salva",
                    width: 30
                });

                pulsantis.push({
                    text: "",
                    icon: "mdi-arrow-left",
                    link: "/back",
                    id: "btn_back_demo",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back-alertmsg.png",
                    title: "Back",
                    width: 35

                });

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

            setTimeout(() => {

                this.formatDropify();

            }, 100);

            this.changeDip();
            setTimeout(() => {
                //  this.syncMonth();
            }, 100);



        },

        manageClickDipendenti: function (dipendente, isGroup) {

            console.log("MANAGE_CLICK_DIPENDENTI");

            console.log(isGroup);
            this.sheetDipendenti = false;
            this.sheetGruppi = false;
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.currDipendente = dipendente.resourceid;
            this.dialogMsgSend = true;

            if (this.currGruppo) {
                this.currGruppo = dipendente;
                this.nomeCorrente = dipendente.nome;

                this.nomeCompletoCorrente = this.currGruppo.nome;

                console.log("this.currGruppo", this.currGruppo);

            } else {
                this.currGruppo = {};

            }

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "",
                    icon: "mdi-arrow-left",
                    link: "/back",
                    id: "btn_back",
                    disabled: false,
                });

                pulsantis.push({
                    text: "",
                    icon: "mdi-email-send-outline",
                    link: "/invia",
                    id: "btn_invia_msg",
                    disabled: false,
                });

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

            setTimeout(() => {

                this.formatDropify();

            }, 100);

            this.changeDip();
            setTimeout(() => {
                //  this.syncMonth();
            }, 100);

        },

        formatDropify: function () {

            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

            });

        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            this.currDipendente = "";
            /*             this.$root.$children[0].curDipTop = "";

                        this.$root.$children[0].curDipOreTop = "";
                        this.$root.$children[0].curDipOreTopLabel = ""; */

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];
            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            console.log("Elenco aziende:", this.aziende);
            for (var az of aziende) {
                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);
                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);
                    }
                }
            }
            this.dipendenti = arrDipendenti;

        },

        // manageClickAziende: async function (azienda) {

        //     this.currAzienda = azienda;
        //     this.buttonsNewMessage();
        //     await this.syncArrayDipendenti();

        //     console.log("CurAzienda", this.currAzienda);
        //     console.log(this.dipendenti);

        //     setTimeout(() => {

        //         this.sheetEmpty = true;

        //         //    this.sheetDipendenti = true;
        //     }, 300);

        // },


        manageClickAziende: async function (azienda) {

            console.log(azienda);

            window.companyDest = azienda.companyid;

            this.sheetAziende = false;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();

        },

        openMsgDialog: function (item) {

            console.log("ITEM: ", item);

            // item.read = "1";

            this.currItem = item;
            this.dialogMsg = true;
            //this.editedItem = Object.assign({}, this.defaultItem)
            this.defaultItem.title = item.title;
            this.defaultItem.body = item.desc;
            this.defaultItem.read = item.read;
            this.defaultItem.id = item.id;

            this.defaultItem.accepted = item.read;

            //  this.updateMessage(item.id);

        },

        closeSendMsg() {
            this.dialogMsgSend = false;
            this.setupButtons();

            setTimeout(() => {
                window.table.ajax.reload();

            }, 100);

        },


        closeSendMsgDemo() {
            this.dialogMsgSendDemo = false;
            this.setupButtons();

            window.table.ajax.reload();
            window.table.ajax.reload();


        },



        close() {
            this.dialogMsg = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        getDataFromApi() {
            this.loading = true
            this.realApiCall().then(data => {
                this.desserts = data.items
                this.totalDesserts = data.total
                this.loading = false
            })
        },

        realApiCall: async function () {
            let items = await this.getMessages();
            //console.log ( "ciao",1 );

            return new Promise((resolve, reject) => {
                //console.log ( "ciao",2 );
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                console.log(reject);

                //const total = items.length

                const total = this.totalDesserts;
                //   alert(total);

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                }
                console.log(itemsPerPage, page);
                /* if (itemsPerPage > 0) {
                    items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                } */

                setTimeout(() => {
                    resolve({
                        items,
                        total,
                    })
                }, 10)
            })
        },

        getMessages: async function () {

            var response;

            var token = window.$cookies.get("token");

            console.log("Options :", this.options);
            var itemsPerPage = this.options.itemsPerPage;

            var start = (this.options.itemsPerPage * this.options.page) - this.options.itemsPerPage;
            var arrMsg = [];
            try {
                response = await this.$api.getMessages(
                    token,
                    start,
                    itemsPerPage
                );

                this.totalDesserts = response.data.Messages.msg_tot;
                this.$root.$children[0].totalMsg = response.data.Messages.msg_tot_nr;

                var msg = response.data.Messages.pagine[0].messaggi;
                console.log("msg", msg);
                /*  for ( let [key] in msg)
                 {
                     //console.log ( "messaggio", msg[key] );
                     arrMsg.push ( msg[key] ) ;

                 } */

                for (let [key, value] of Object.entries(msg)) {
                    console.log("Messaggio", key, value);
                    arrMsg.push(value);
                }

            } catch (error) {
                // this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
            return arrMsg;


        },

        setupButtons: async function () {
            setTimeout(() => {

                var pulsantis = [];

                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");

                // pulsantis.push({
                //     text: "",
                //     icon: "mdi-message-plus",
                //     link: "/dash",
                //     id: "btn_filter_status",
                //     disabled: false,
                //     image: "https://app.emtool.eu/public/_lib/img/filtro-stato-alertmsg.png",
                //     title: "Stato",
                //     width: 30

                // });


                if (!this.$root.$children[0].demo_version) {

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                        pulsantis.push({
                            text: "",
                            icon: "mdi-message-plus",
                            link: "/dash",
                            id: "btn_aziende",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                            title: "Aggiungi",
                            width: 30

                        });
                    }

                }
                else {


                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                        pulsantis.push({
                            text: "",
                            icon: "mdi-message-plus",
                            link: "/dash",
                            id: "btn_aziende_demo",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                            title: "Aggiungi",
                            width: 30

                        });
                    }




                }

                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },


        hideShowButtonsSelRow: function () {

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");
            var user = window.$cookies.get("username");

            console.log("USERNAME: ", user);

            console.log("CUR ITEM ROW: ", this.currItemRow);


            window.numEventSelRow = 0;

            var tot = 0;
            var cnt_msg_read = 0;

            var check_mail = 0;
            var check_mobile = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    var is_read = $(this).attr("read-dest");

                    check_mail = $(this).attr("check-mail");

                    check_mobile = $(this).attr("check-mobile");

                    if (is_read == 1) {
                        cnt_msg_read = cnt_msg_read + 1;
                    }

                }

            });




            console.log("TOT ROW SEL: ", tot);
            console.log("TOT ROW MSG READ: ", cnt_msg_read);

            setTimeout(() => {

                var pulsantis = [];


                if (!this.$root.$children[0].demo_version) {

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                        if (tot == 0) {

                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });


                        }


                        if (tot > 0) {


                            pulsantis.push({
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/delete-alertmsg.png",
                                link: "/info",
                                id: "btn_delete_row",
                                disabled: false,
                                title: "Elimina",
                                width: 30

                            });


                            if (cnt_msg_read == 0) {

                                pulsantis.push({
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/invio-alertmsg.png",
                                    link: "/info",
                                    id: "btn_resend",
                                    disabled: false,
                                    title: "Reinvia",
                                    width: 30

                                });



                            }



                        }


                    }


                    if (tot == 1) {



                        var v_row = $("table").find(".active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);

                        this.currItemRow = item;

                        console.log("IS SISGNATURE: ", this.currItemRow.signature);


                        console.log("TOT: ", tot);


                        pulsantis.push({
                            text: "",
                            icon: "mdi-eye",
                            link: "/dash",
                            id: "btn_view",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/view_alertmsg.png",
                            title: "View",
                            width: 35

                        });




                    }



                }
                else {

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {


                        if (tot == 0) {

                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende_demo",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });


                        }


                        if (tot > 0) {


                            pulsantis.push({
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/delete-alertmsg.png",
                                link: "/info",
                                id: "btn_delete_row",
                                disabled: false,
                                title: "Elimina",
                                width: 30

                            });

                            if (cnt_msg_read == 0) {

                                pulsantis.push({
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/invio-alertmsg.png",
                                    link: "/info",
                                    id: "btn_resend",
                                    disabled: false,
                                    title: "Reinvia",
                                    width: 30

                                });



                            }

                        }


                    }


                    if (tot == 1) {

                        var v_row2 = $("table").find(".active-row");

                        var item2 = window.table.row(v_row2).data();

                        console.log("ITEM ROW: ", item2);

                        this.currItemRow = item2;

                        console.log("TOT: ", tot);

                        console.log("IS SISGNATURE: ", this.currItemRow.signature);


                        // questo view

                        if ((a == "N") && (b == "N")) {

                            if ((check_mail == 1) && (check_mobile == 1)) {
                                pulsantis.push({
                                    text: "",
                                    icon: "mdi-eye",
                                    link: "/dash",
                                    id: "btn_view",
                                    disabled: false,
                                    image: "https://app.emtool.eu/public/_lib/img/view_alertmsg.png",
                                    title: "View",
                                    width: 35

                                });
                            }
                            else {

                                pulsantis.push({
                                    text: "",
                                    icon: "mdi-eye",
                                    link: "/dash",
                                    id: "btn_verify",
                                    disabled: false,
                                    image: this.getUrlDomain("public/_lib/img/verify_alertmsg.png"),
                                    title: "Verify Mail And Mobile",
                                    width: 30

                                });

                            }

                        }
                        else {
                            pulsantis.push({
                                text: "",
                                icon: "mdi-eye",
                                link: "/dash",
                                id: "btn_view",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/view_alertmsg.png",
                                title: "View",
                                width: 35

                            });

                        }







                    }


                }



                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },



        hideShowButtons: async function (v_enable) {

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");
            var user = window.$cookies.get("username");

            console.log("USERNAME: ", user);

            window.numEventSelRow = 0;

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            console.log("HIDE SHOW BTS TOT: ", tot);

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    if (!this.$root.$children[0].demo_version) {

                        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });

                        }

                    }
                    else {

                        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {


                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende_demo",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });




                        }

                    }

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                        if (tot > 0) {


                            pulsantis.push({
                                text: "",
                                icon: "mdi-delete",
                                link: "/delete",
                                id: "btn_delete_row",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/usr__NM__new_icon_delete_32.png",
                                class: "inverted",
                                title: "Elimina"

                            });

                        }


                    }


                }

                if (v_enable == true) {


                    if (!this.$root.$children[0].demo_version) {

                        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });
                        }



                    }
                    else {

                        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende_demo",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });
                        }

                    }

                    pulsantis.push({
                        text: "",
                        icon: "mdi-eye",
                        link: "/dash",
                        id: "btn_view",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/view_alertmsg.png",
                        title: "View",
                        width: 35

                    });



                    if (a == 'Y' || a == 'S') {

                        if (tot > 0) {


                            pulsantis.push({
                                text: "",
                                icon: "mdi-delete",
                                link: "/delete",
                                id: "btn_delete_row",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/usr__NM__new_icon_delete_32.png",
                                class: "inverted",
                                title: "Elimina"

                            });

                        }


                    }

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        buttonsNewMessage: async function () {
            setTimeout(() => {
                var pulsantis = [
                    /* {
                                        text: "Dashboard",
                                        nsertcon: "mdi-home-circle",
                                        link: "/dash",
                                        id: "btn_exit",
                                        disabled: false,
                                    }, */
                ];
                //var a = window.$cookies.get("a");

                //if (a == 'Y' || a == 'S') {
                pulsantis.push({
                    text: "",
                    icon: "mdi-arrow-left",
                    link: "/back",
                    id: "btn_back",
                    disabled: false,
                });


                /*    pulsantis.push({
                       text: "",
                       icon: "mdi-domain",
                       link: "/aziende",
                       id: "btn_companies",
                       disabled: false,
                   }, ); */

                if (this.currAzienda) {
                    pulsantis.push({
                        text: "",
                        icon: "mdi-account",
                        link: "/dipendenti",
                        id: "btn_dipendenti",
                        disabled: false,
                    });

                    pulsantis.push({
                        text: "",
                        icon: "mdi-account-multiple",
                        link: "/gruppi",
                        id: "btn_gruppi",
                        disabled: false,
                    });
                }

                //}
                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },

        updateMessage: async function (id_msg) {

            //  var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");

            this.$root.$children[0].totalMsg = this.$root.$children[0].totalMsg - 1;

            var response;


            // console.log(username, token_system);
            try {
                response = this.$api.updateStatusMsgApi(
                    token,
                    id_msg
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
        },

        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
#fldValueOtpLoginPrjweb {
    text-align: center;
    font-size: 14px;
}


#firmaCanvas {
    border: 1px solid black;
}


canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#container-view-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
}


#fldValueOtpRead {
    text-align: center;
    font-size: 20px;
}

.cont-table-msg {
    font-size: 10px;
    padding-top: 4px;
    margin: 0 auto;
}

.cont-table-msg .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.divCont {
    max-width: 500px;
    text-align: center;
    margin: auto;
}



.cl-checkbox i {
    font-size: 13px !important;
}

.cl-checkbox .v-label {
    font-size: 11px !important;
}

.cont_messaggi_base_users .col_n {}

.cont_messaggi_base_users .selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_messaggi_base_users .selstato {
    max-width: 28px;
    min-height: 28px;
    margin-left: 5px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 28px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}


.header_style {

    background-color: #da6a2d;
    padding: 5px 5px 5px 5px;
    text-align: left;

}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.dialogMsg {
    min-width: 100vw !important;
}

span.corrente {
    margin-left: 4px;
}

.imgread1 {
    visibility: hidden;
}

.imgread0 {
    visibility: visible;
    width: 22px;
}

.msgread1 {
    font-weight: normal;
}

.msgread0 {
    font-weight: bold;
}

.v-application .elevation-1 {
    box-shadow: none !important;
    ;
}

span.hour {
    font-size: 10px;
}

.cont_messaggi_base_users .msgDest {
    font-size: 10px;
}

.cont_messaggi_base_users .msgDesc {
    min-width: 66px;
    font-size: 10px;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.files label {
    margin-left: 0px !important;
}

.cont_messaggi_base_users #myTable_wrapper {
    min-width: initial;
    min-width: 1200px;
    max-width: 1200px;
    width: 1200px;
    font-size: 11px;
    color: black;
    font-weight: bold;

}

.cont_messaggi_base_users .nome_parente {
    min-width: 200px;
}

.cont_messaggi_base_users .inserito {
    min-width: 70px;
}

.cont_messaggi_base_users .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.cont_messaggi_base_users .mail {
    min-width: 200px;
}

.cont_messaggi_base_users td:first-child {
    padding: 10px !important;
}

.cont_messaggi_base_users #myTable_filter {
    padding-top: 1px;
    padding-bottom: 1px;
    position: fixed;
    left: 0px;
    max-width: 100%;
    background-color: white;
    border-color: #da6a2d;

}

.cont_messaggi_base_users #myTable {
    margin-right: 3px;
    max-width: 1185px;
}

.cont_messaggi_base_users table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_messaggi_base_users table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 30px !important;
}

.cont_messaggi_base_users th {
    padding-left: 5px !important;
}

.cont_messaggi_base_users ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_messaggi_base_users .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_messaggi_base_users .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 80px !important;
    padding-left: 0px !important;
}

.cont_messaggi_base_users #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

.cont_messaggi_base_users div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_messaggi_base_users #myTable_wrapper {
    padding-top: 0px;
}

.cont_messaggi_base_users .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 80px;
    text-align: left;
}

.cont_messaggi_base_users .cl_active {
    display: inline-flex;
    align-items: center;
    /* justify-content: flex-start!important; */
    /*  text-align: center; */
    min-width: 100%;

    justify-content: center;
}

.cont_messaggi_base_users .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 100px;
    padding-left: 10px;
    justify-content: inherit;

}

.cl_vcard {
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 150px;
}

.mainTitleInsMsg {
    padding: 15px !important;
    position: fixed !important;
    min-width: 100vw !important;
    z-index: 3 !important;
    top: 64px !important;
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 100vw;
    z-index: 3;
    border-color: #da6a2d !important;
}

.fieldsMsgSendDemo {
    margin-top: 55px !important;
}

.fieldsDialogMsg {
    margin-top: 55px !important;
}

.cl_is_signatured {
    text-align: center;
}

.cl_signature_enabled {
    text-align: left;
    padding-left: 42px;
}

.cont_messaggi_base_users .cl_checkall {
    padding-left: 5px;
    padding-top: 2px;
}

.cont_messaggi_base_users .checkall {
    width: 13px;
    height: 13px;
}

.cont_messaggi_base_users .val_status {
    width: 10px !important;
}

.cont_messaggi_base_users .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_messaggi_base_users .selazienda {
    max-width: 26px;
    min-height: 26px;
    margin-left: 7px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 26px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;

}



@media screen and (max-width: 768px) {

    .cont_messaggi_base_users .att {
        display: inline-block;
        padding-left: 11px;
    }


    .cont_messaggi_base_users .cl_checkall {
        padding-left: 24px;
        padding-top: 2px;
    }


    .cont_messaggi_base_users .checkall {
        width: 13px;
        height: 13px;
    }


    .cl_cmp_mitt {
        display: inline-flex;
        padding-left: 16px;
    }

    .cl_is_signatured {
        text-align: center;
        display: inline;
        padding-left: 20px;
    }

    .cl_signature_enabled {
        display: inline;
        padding-left: 6px;
    }

    .header_style {

        background-color: #da6a2d;
        padding: 5px 5px 5px 5px;
        text-align: left;

    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .cont_messaggi_base_users .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_messaggi_base_users #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        width: 100vw !important;
    }

    .cont_messaggi_base_users #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_messaggi_base_users .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;

    }

    .cont_messaggi_base_users #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_messaggi_base_users .col_n {
        margin-left: -3px;
    }

    .mainTitleInsMsg {
        padding: 15px !important;
        position: fixed !important;
        min-width: 100vw !important;
        z-index: 3 !important;
        top: 56px !important;
        border: 1px solid black !important;
        background-color: #da6a2d;
        color: white;
        font-weight: bold;
        padding: 4px;
        font-size: 10px;
        min-width: 100vw;
        z-index: 3;
    }

    .cont_messaggi_base_users .msgDest {
        font-size: 10px;
        display: inline;
        margin-left: 16px;
    }


    .cont_messaggi_base_users .cl_cmp_dest {
        display: inline;
        margin-left: 16px;
    }



}

.buttonsPdf {
    margin-bottom: 150px;
    padding-top: 10px;
}

.buttonsPdf button {
    margin-right: 6px;
    margin-left: 6px;


}

.contSignature {
    margin: 0 auto;
}

.contSignature canvas {

    max-width: 98vw;
    border: 1px solid black;
    padding-bottom: 10px;
    padding-top: 10px;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}
</style>
